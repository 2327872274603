import {Box} from '@chakra-ui/react'
import Pokemon from './Pokemon';

function pokedex() {
  return (
      <Box>
        <Pokemon/>
      </Box>
  )
}

export default pokedex;