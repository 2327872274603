import React from "react";
import CharacterModule from "./Card";

export function CreateChar() {
  return (
      <>
        <CharacterModule
            feet={'feet1'}
        />
      </>
  );
}